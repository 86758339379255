<template>
  <b-sidebar
    id="sidebar-task-handler"
    sidebar-class="sidebar-xl"
    :visible="isShowEmanualSidebar"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-show-emanual-sidebar', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          p-1
        "
        v-if="emanualValue.length !== 0"
      >
        <h2 class="mb-0"></h2>
      </div>
      <div
        class="text-center text-danger my-2 py-5"
        v-if="emanualValue.length === 0"
      >
        <NoData />
      </div>
      <div v-else>
        <AppCollapseItem
          v-for="(emanual, index) in emanualValue"
          :key="index"
          :isVisible.sync="emanual.isCollapsed"
          class="emanual-item cursor-pointer"
          isShowTitle
        >
          <template #header>
            <div class="d-flex">
              <div class="emanual-no mr-2">
                {{ `${index + 1}` }}
              </div>
              <div class="d-flex justify-content-between flex-1">
                <div
                  class="
                    content-sidebar-header
                    d-flex
                    flex-1
                    align-items-center
                  "
                >
                  <h3 class="pl-3 mb-0">{{ emanual.title }}</h3>
                </div>
              </div>
            </div>
          </template>
          <div class="mb-2">
            <div v-html="emanual.description"></div>
            <div class="d-flex justify-content-end">
              <base-button
                type="primary"
                size="sm"
                @click="emanual.showAttachedFile = !emanual.showAttachedFile"
                class="ml-2"
                v-if="emanual.attachments.length !== 0"
              >
                <span>
                  {{ $t('button.view_attached_file') }}
                </span>
              </base-button>
              <a
                v-if="emanual.emanual_current_locale_link"
                class="btn btn-primary px-3 py-2"
                :href="emanual.emanual_current_locale_link"
                target="blank"
              >
                <span>{{ $t('button.view_more') }}</span>
              </a>
            </div>
          </div>
          <AttachMedia
            v-if="emanual.showAttachedFile"
            ref="attachMedia"
            model="Emanual"
            :media="emanual.attachments"
            :showUploadDragger="false"
            :role_upload="false"
          />
        </AppCollapseItem>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'
import AppCollapseItem from '@frontendCommon/components/AppCollapse/AppCollapseItem'
import AttachMedia from '@frontendCommon/components/AttachMedia'

export default {
  name: 'EmanualSidebar',
  components: {
    AppCollapseItem,
    BSidebar,
    AttachMedia,
  },
  model: {
    prop: 'isShowEmanualSidebar',
    event: 'update:is-show-emanual-sidebar',
  },
  props: {
    isShowEmanualSidebar: {
      type: Boolean,
      default: false,
    },
    emanualData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      emanualValue: [],
    }
  },
  watch: {
    isShowEmanualSidebar: {
      handler(val) {
        if (val) {
          this.emanualValue = this.$props.emanualData
        }
      },
    },
  },
}
</script>
<style lang="css" scoped>
.emanual-wrapper {
  border-bottom: 1px solid #dadada;
  padding-bottom: 1rem;
}
.emanual-wrapper:not(:last-child) {
  margin-bottom: 1rem;
}

.emanual-wrapper .emanual-choices {
  display: flex;
  flex-direction: column;
}

.emanual-wrapper .emanual-choices .choice {
  border: 1px solid #dadada;
  margin-right: 10px;
  transition: all 0.2s;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  position: relative;
  cursor: pointer;
}

.emanual-wrapper .emanual-choices .choice:not(:last-child) {
  margin-bottom: 0.8rem;
}

.emanual-wrapper .emanual-choices .choice:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background: #ffffff;
  border: 5px solid #dadada;
  border-radius: 9999px;
  transition: all 0.2s;
}

.emanual-wrapper .emanual-choices .choice:hover,
.emanual-wrapper .emanual-choices .choice.active {
  border: 1px solid #2dce89;
}

.emanual-wrapper .emanual-choices .choice:hover:after,
.emanual-wrapper .emanual-choices .choice.active:after {
  border: 5px solid #2dce89;
}

.emanual-wrapper .emanual-choices.disabled .choice {
  cursor: not-allowed;
}

.emanual-wrapper .emanual-choices.disabled .choice:hover {
  border: 1px solid #dadada;
}

.emanual-wrapper .emanual-choices.disabled .choice:hover:after {
  border: 5px solid #dadada;
}

.emanual-list ::v-deep .card-header {
  position: relative;
  z-index: 1;
  padding: 0.8rem 1.2rem;
}

.emanual-list ::v-deep .card-body {
  padding: 0.8rem 1.2rem;
}

.emanual-list ::v-deep .collapse {
  background: #fcfcfc;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.emanual-no,
.emanual-no-sub {
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: bold;
}

.emanual-no {
  width: 31px;
  height: 31px;
  background: #2dce89;
  color: #fff;
  font-size: 18px;
}

.emanual-no-sub {
  width: 27px !important;
  height: 27px !important;
  background: rgba(45, 206, 137, 0.2);
  color: #2dce89;
  margin-right: 10px;
  font-size: 15px;
}

.emanual-no-not-evaluate {
  background: #575757;
}

.emanual-item {
  transition: all 0.2s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03), 0px 0px 4px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
}

.emanual-item:hover .draggable-emanual-handle {
  visibility: visible;
}

.button-collapse {
  border: 1px solid #dadada;
}

.button-collapse:hover,
.button-collapse:focus,
.button-collapse:active,
.button-collapse:target {
  box-shadow: none !important;
  border: 1px solid #dadada;
}

.button-collapse:hover {
  background: #e6e6e6;
}

.b-sidebar .b-sidebar-body .content-sidebar-header {
  background-color: #fff !important;
}
</style>

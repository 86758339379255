<template>
  <label :class="switchClass" class="custom-toggle">
    <input v-model="model" type="checkbox" :disabled="disabled">
    <span
      :data-label-off="offText"
      :data-label-on="onText"
      class="custom-toggle-slider"
    />
  </label>
</template>
<script>
export default {
  name: 'BaseSwitch',
  props: {
    value: [Array, Boolean],
    type: String,
    onText: {
      type: String,
      default: 'Yes'
    },
    offText: {
      type: String,
      default: 'No'
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    switchClass () {
      const baseClass = 'custom-toggle-'
      if (this.type) {
        return baseClass + this.type
      }
      return ''
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    triggerToggle () {
      this.model = !this.model
    }
  }
}
</script>
<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SlideYUpTransition',{attrs:{"duration":_vm.animationDuration}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal fade",class:[
      { 'show d-block': _vm.show },
      { 'd-none': !_vm.show },
      { 'modal-mini': _vm.type === 'mini' },
    ],attrs:{"aria-hidden":!_vm.show,"tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",class:[
        { 'modal-notice': _vm.type === 'notice', [`modal-${_vm.size}`]: _vm.size },
        _vm.modalClasses,
      ]},[_c('div',{staticClass:"modal-content modal-content-custom",class:[
          _vm.gradient ? `bg-gradient-${_vm.gradient}` : '',
          _vm.modalContentClasses,
        ]},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header",class:[_vm.headerClasses]},[_vm._t("header"),_vm._v(" "),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeModal}},[_c('img',{attrs:{"src":"/img/icons/close_ico.svg","alt":""}})]):_vm._e()]})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
      v-if="showTitle"
    >
      <slot name="header">
        <span class="collapse-title">{{ title }}</span>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body
        :class="{
          'p-0': noPadding,
          'pb-0': noPaddingBottom,
          'pt-0': noPaddingTop,
          'pl-0': noPaddingLeft,
          'pr-0': noPaddingRight,
        }"
      >
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCollapse } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isShowTitle: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noPaddingTop: {
      type: Boolean,
      default: false,
    },
    noPaddingRight: {
      type: Boolean,
      default: false,
    },
    noPaddingBottom: {
      type: Boolean,
      default: false,
    },
    noPaddingLeft: {
      type: Boolean,
      default: false,
    },
    preventHeaderBodyClick: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    showTitle: {
      get() {
        return this.isShowTitle
      },
      set(val) {
        this.$emit('update:isShowTitle', val)
      },
    },
    visible: {
      get() {
        return this.isVisible
      },
      set(val) {
        this.$emit('update:isVisible', val)
      },
    },
  },
  watch: {
    title: {
      handler(val) {
        if (val) {
          this.showTitle = true
        }
      },
      immediate: true,
    },
  },
  created() {
    this.collapseItemID = uuidv4()
  },
  methods: {
    updateVisible(val = true) {
      if (this.preventHeaderBodyClick) return
      this.visible = val
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>

<style scoped lang="scss">
@import './collapse.scss';
</style>

// state
export const state = () => ({
  taxProvince: [],
  taxDistrict: [],
  taxWard: [],
})

// getters
export const getters = {
  taxProvince: (state) => state.taxProvince,
  taxDistrict: (state) => state.taxDistrict,
  taxWard: (state) => state.taxWard,
}

// mutations
export const mutations = {
  SET_TAX_PROVINCE(state, taxProvince) {
    state.taxProvince = taxProvince
  },

  SET_TAX_DISTRICT(state, taxDistrict) {
    state.taxDistrict = taxDistrict
  },

  SET_TAX_WARD(state, taxWard) {
    state.taxWard = taxWard
  },
}

// actions
export const actions = {
  updateTaxProvince({ commit }, payload) {
    commit('SET_TAX_PROVINCE', payload)
  },

  updateTaxDistrict({ commit }, payload) {
    commit('SET_TAX_DISTRICT', payload)
  },

  updateTaxWard({ commit }, payload) {
    commit('SET_TAX_WARD', payload)
  },
}

<template>
  <div>
    <div v-if="typeUi == 'box'">
      <div class="form-control-label d-flex justify-content-between" v-if="isShowTitle">
        <label for="">
          <span v-if="required" class="text-danger">*</span> {{ $t("model.media.attachment") }}:
        </label>
        <span class="font-weight-400"
          >{{ $t("model.media.total") }}: {{ total }}/{{ limit }}</span
        >
      </div>
      <slot name="before-dropzone"></slot>
      <el-upload
        :drag="role_upload"
        :multiple="multiple"
        :disabled="!role_upload || disabled"
        ref="upload"
        :class="displayByFlex ? 'upload-demo by-flex' : 'upload-demo'"
        action=""
        :auto-upload="false"
        :on-change="processFile"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :file-list="fileList"
        :limit="limit"
        :on-preview="downloadFile"
        list-type="picture"
        :accept="acceptTypes"
      >
        <div
          class="el-upload-dragger-desc text-center py-3 d-flex justify-content-center align-items-center"
          v-if="role_upload"
        >
          <div>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background: new 0 0 512 512; width: 50px"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M458.667,192H352c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h106.667c17.643,0,32,14.357,32,32
              v213.333c0,17.643-14.357,32-32,32H245.333c-17.643,0-32-14.357-32-32v-85.333c0-5.888-4.779-10.667-10.667-10.667
              S192,367.445,192,373.333v85.333C192,488.064,215.936,512,245.333,512h213.333C488.064,512,512,488.064,512,458.667V245.333
              C512,215.936,488.064,192,458.667,192z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M160,298.667h-10.667c-5.888,0-10.667,4.779-10.667,10.667S143.445,320,149.333,320H160
              c5.888,0,10.667-4.779,10.667-10.667S165.888,298.667,160,298.667z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path
                      d="M62.741,0h-9.408C48.235,0,43.2,0.725,38.357,2.133c-5.653,1.664-8.896,7.595-7.253,13.248
                c1.365,4.651,5.632,7.659,10.24,7.659c1.003,0,2.005-0.128,3.008-0.427c2.901-0.853,5.909-1.28,8.981-1.28h9.408
                c5.909,0,10.667-4.779,10.667-10.667S68.629,0,62.741,0z"
                    />
                    <path
                      d="M21.333,257.216v-21.568c0-5.888-4.779-10.667-10.667-10.667S0,229.76,0,235.648v21.568
                c0,5.888,4.779,10.667,10.667,10.667S21.333,263.104,21.333,257.216z"
                    />
                    <path
                      d="M10.667,203.179c5.888,0,10.667-4.779,10.667-10.667v-21.568c0-5.888-4.779-10.667-10.667-10.667S0,165.056,0,170.944
                v21.568C0,198.4,4.779,203.179,10.667,203.179z"
                    />
                    <path
                      d="M10.667,73.792c5.888,0,10.667-4.779,10.667-10.667v-9.792c0-2.965,0.405-5.888,1.195-8.704
                c1.6-5.675-1.685-11.563-7.36-13.163c-5.632-1.685-11.563,1.664-13.163,7.36C0.683,43.541,0,48.427,0,53.333v9.792
                C0,69.013,4.779,73.792,10.667,73.792z"
                    />
                    <path
                      d="M10.667,138.496c5.888,0,10.667-4.779,10.667-10.667v-21.568c0-5.888-4.779-10.667-10.667-10.667S0,100.373,0,106.261
                v21.568C0,133.717,4.779,138.496,10.667,138.496z"
                    />
                    <path
                      d="M44.587,297.451c-5.163-1.472-9.941-4.267-13.781-8.085c-4.16-4.16-10.923-4.117-15.083,0.043
                c-4.16,4.181-4.139,10.944,0.043,15.083c6.4,6.379,14.357,11.029,22.997,13.483c0.981,0.277,1.963,0.405,2.923,0.405
                c4.629,0,8.917-3.051,10.24-7.744C53.547,304.96,50.261,299.051,44.587,297.451z"
                    />
                    <path
                      d="M307.605,51.968c0.981,0,1.984-0.128,2.987-0.448c5.653-1.664,8.917-7.573,7.253-13.227
                c-2.517-8.619-7.253-16.533-13.675-22.891c-4.181-4.139-10.944-4.117-15.083,0.085c-4.139,4.181-4.096,10.944,0.085,15.083
                c3.84,3.84,6.699,8.576,8.192,13.717C298.731,48.939,302.976,51.968,307.605,51.968z"
                    />
                    <path
                      d="M256.832,0h-21.568c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h21.568
                c5.888,0,10.667-4.779,10.667-10.667S262.72,0,256.832,0z"
                    />
                    <path
                      d="M106.219,298.667H84.651c-5.888,0-10.667,4.779-10.667,10.667S78.763,320,84.651,320h21.568
                c5.888,0,10.667-4.779,10.667-10.667S112.107,298.667,106.219,298.667z"
                    />
                    <path
                      d="M309.333,73.6c-5.888,0-10.667,4.779-10.667,10.667v21.568c0,5.888,4.779,10.667,10.667,10.667S320,111.723,320,105.835
                V84.267C320,78.357,315.221,73.6,309.333,73.6z"
                    />
                    <path
                      d="M192.149,0h-21.568c-5.909,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h21.547
                c5.909,0,10.688-4.779,10.688-10.667S198.037,0,192.149,0z"
                    />
                    <path
                      d="M127.445,0h-21.568c-5.888,0-10.667,4.779-10.667,10.667s4.779,10.667,10.667,10.667h21.568
                c5.888,0,10.667-4.779,10.667-10.667S133.333,0,127.445,0z"
                    />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M309.333,138.667c-5.888,0-10.667,4.779-10.667,10.667V160c0,5.888,4.779,10.667,10.667,10.667S320,165.888,320,160
              v-10.667C320,143.445,315.221,138.667,309.333,138.667z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M349.952,185.515l-33.067-33.067c-12.971-12.971-34.112-12.971-47.083,0c-3.883,3.883-6.613,8.512-8.171,13.419
              c-11.627-4.053-25.536-1.045-34.496,7.915c-4.885,4.885-7.915,10.923-9.131,17.237c-10.176-1.429-20.8,2.027-28.203,9.429
              c-7.083,7.083-10.304,16.576-9.664,25.856c-7.189,0.981-13.824,4.245-19.029,9.451c-6.293,6.293-9.749,14.656-9.749,23.552
              s3.456,17.28,9.749,23.552l9.557,9.557v38.251C170.667,360.064,194.603,384,224,384h43.733C331.84,384,384,331.84,384,267.733
              C384,236.693,371.904,207.488,349.952,185.515z M267.733,362.667H224c-17.643,0-32-14.357-32-32v-16.917l24.469,24.469
              c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552c0-2.731-1.045-5.461-3.115-7.552l-55.36-55.36
              c-2.261-2.24-3.499-5.269-3.499-8.448c0-3.179,1.237-6.187,3.499-8.448c4.523-4.523,12.395-4.523,16.917,0l23.36,23.36
              c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552c0-2.731-1.045-5.461-3.115-7.531l-26.667-26.667
              c-4.672-4.672-4.672-12.245,0-16.917c4.523-4.523,12.395-4.523,16.917,0l16,16c4.16,4.16,10.923,4.16,15.083,0
              c2.069-2.091,3.115-4.821,3.115-7.552s-1.045-5.461-3.115-7.531l-10.667-10.667c-4.672-4.672-4.672-12.245,0-16.917
              c4.523-4.523,12.395-4.523,16.917,0l10.667,10.667c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552
              s-1.045-5.461-3.136-7.552c-2.261-2.24-3.499-5.248-3.499-8.448s1.259-6.208,3.52-8.448c4.672-4.672,12.245-4.672,16.917,0
              l33.067,33.067c17.92,17.92,27.797,41.771,27.797,67.115C362.667,320.085,320.085,362.667,267.733,362.667z"
                  />
                </g>
              </g>
            </svg>
            <div class="mt-2" v-html="$t('attachmedia.desc')"></div>
            <div class="mt-2 text-danger" v-if="showAlert">
              {{ $t("attachmedia.valid_entensions") }}
            </div>
          </div>
        </div>

        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <a :href="file.download" download target="_blank">{{ file.name }}</a>
          <div v-show="!loading">
            <input
              v-if="canAddDescription"
              type="text"
              class="desc-box"
              :placeholder="$t('fields.input.work_description')"
              :readonly="!role_upload"
              v-model="file.description"
              @change="updateDescription(file)"
            />
            <div v-if="mustHaveDescription && !file.description">
              <small class="text-danger">{{
                $t("importing.required_msg", {
                  msg: $t("fields.input.description"),
                })
              }}</small>
            </div>
          </div>
          <div
            v-if="isShowPermissionDeleteFile()"
            class="media-remove"
            @click="handleRemove(file)"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="trash"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="svg-inline--fa fa-trash"
            >
              <path
                fill="currentColor"
                d="M432 64C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H413.7L388.2 452.6C385.9 486.1 357.1 512 324.4 512H123.6C90.01 512 62.15 486.1 59.75 452.6L34.29 96H16C7.164 96 0 88.84 0 80C0 71.16 7.164 64 16 64H111.1L137 22.56C145.8 8.526 161.2 0 177.7 0H270.3C286.8 0 302.2 8.526 310.1 22.56L336.9 64H432zM177.7 32C172.2 32 167.1 34.84 164.2 39.52L148.9 64H299.1L283.8 39.52C280.9 34.84 275.8 32 270.3 32H177.7zM381.6 96H66.37L91.67 450.3C92.87 467 106.8 480 123.6 480H324.4C341.2 480 355.1 467 356.3 450.3L381.6 96z"
                class=""
              ></path>
            </svg>
          </div>
        </div>
      </el-upload>
    </div>
    <div v-else>
      <el-upload
        drag
        :multiple="multiple"
        :disabled="!role_upload || disabled"
        ref="upload"
        class="upload-tiny"
        action=""
        :auto-upload="false"
        :on-change="processFile"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :file-list="fileList"
        :limit="limit"
        :on-preview="downloadFile"
        list-type="picture"
        :accept="acceptTypes"
        :show-file-list="false"
      >
        <el-button size="small" type="primary">
          <span class="upload-button-text white-space-normal">{{
            fileList.length > 0 ? fileList[0].name : "Upload"
          }}</span>
        </el-button>
      </el-upload>
      <el-button
        @click="reset"
        v-if="fileList.length > 0 && isShowPermissionDeleteFile()"
        size="small"
        type="danger"
      >
        <span class="upload-button-text">Xóa File</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import { reject, pluck, filter } from "ramda";
import Helper from "@/util/helper";
import Swal from "sweetalert2";

const moment = require("moment");

export default {
  name: "AttachMedia",
  props: {
    id: {
      type: String,
      default: () => "",
    },
    model: {
      type: String,
      default: () => "",
    },
    mediaCollection: {
      type: String,
      default: () => "",
    },
    multiple: {
      type: Boolean,
      default: () => false,
    },
    limit: {
      type: Number,
      default: () => 5,
    },
    media: {
      type: Array,
      default: () => [],
    },
    media_path: {
      type: String,
      default: () => "",
    },
    showListAfterUpload: {
      type: Boolean,
      default: () => true,
    },
    accept: {
      type: String,
      default: () => "",
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    autoUpload: {
      type: Boolean,
      default: () => false,
    },
    role_upload: {
      type: Boolean,
      default: () => true,
    },
    graphModel: {
      type: String,
      default: () => "Media",
    },
    token: {
      type: String,
      default: () => "",
    },
    showUploadDragger: {
      type: Boolean,
      default: true,
    },
    typeUi: {
      type: String,
      default: () => "box",
    },
    showAlert: {
      type: Boolean,
      default: false,
    },
    canAddDescription: {
      type: Boolean,
      default: false,
    },
    mustHaveDescription: {
      type: Boolean,
      default: false,
    },
    isNotShowIconRemove: {
      type: Boolean,
      default: false,
    },
    displayByFlex: {
      type: Boolean,
      default: false,
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
    limitSize: {
      default: 0, // MB
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let fileList = [];

    this.media.forEach((m) => {
      let icon = ["image/jpeg", "image/png", "image/gif"].includes(m.mime_type)
        ? m.url
        : "/img/icons/file.jpg";
      let download = m.url;
      let created_at = moment(m.created_at).format("YYYY/MM/DD");

      fileList.push({
        id: m.id,
        name: "[" + created_at + "] " + m.file_name,
        url: icon,
        download: download,
        description: m.description,
      });
    });

    return {
      uploadedFile: null,
      path: "",
      changed: false,
      loading: false,
      fileList: fileList,
      tempFileList: [],
      removeFileList: [],
      image_mime_types: ["image/jpeg", "image/png", "image/gif"],
      descriptions: {},
    };
  },
  computed: {
    collection() {
      if (this.mediaCollection) {
        return this.mediaCollection;
      } else {
        return this.model;
      }
    },
    total() {
      return this.fileList.length;
    },
    acceptTypes() {
      if (this.accept) {
        const types = this.accept.split(",");

        return types.reduce((a, b) => {
          let type = "";
          const selector = b.trim();

          switch (selector) {
            case "excel":
              type =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx";
              break;
            case "word":
              type =
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document, .docx";
              break;
            case "file":
              type =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              break;
            case "image":
              type = "image/png, image/gif, image/jpeg";
              break;
            case "pdf":
              type = "application/pdf";
              break;
            default:
              break;
          }
          return `${a},${type}`;
        }, "");
      }

      return "";
    },
  },
  watch: {
    media() {
      this.reset();

      this.media.forEach((m) => {
        let icon = this.image_mime_types.includes(m.mime_type)
          ? m.url
          : "/img/icons/file.jpg";
        let download = !m.url
          ? this.media_path + this.model + "/" + m.id + "/" + m.file_name
          : m.url;
        let created_at = moment(m.created_at).format("YYYY/MM/DD");

        this.fileList.push({
          id: m.id,
          name: "[" + created_at + "] " + m.file_name,
          url: icon,
          download: download,
          description: m.description,
          path: m.file_name,
        });
      });
    },
    showUploadDragger() {
      this.toggleUploadDragger();
    },
    tempFileList(value) {
      this.$emit("tempFileList", value);
    },
  },
  mounted() {
    if (this.showUploadDragger) {
      this.toggleUploadDragger();
    }
  },
  methods: {
    reset() {
      this.$refs.upload.clearFiles();
      this.uploadedFile = null;
      this.fileList = [];
      this.tempFileList = [];
      this.removeFileList = [];
    },
    beforeRemove(file, fileList) {
      if (window.event.type == "keydown" && window.event.keyCode == 8)
        return false;

      return true;
    },
    async handleRemove(file, fileList) {
      if (!file.temp) {
        Swal.fire({
          title: this.$t("model.notifications.confirm_delete"),
          text: "",
          icon: "error",
          confirmButtonText: "OK",
          cancelButtonText: this.$t("model.buttons.cancel"),
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            this.removeFileList.push(file);
            const res = await this.processRemove(file.id);
            this.fileList = reject((f) => f.name == file.name, this.fileList);

            return;
          },
        });
      } else {
        const fileName = file.name.split(" ")[1];

        this.tempFileList = reject(
          (f) => f.name == fileName,
          this.tempFileList
        );
        this.fileList = reject(
          (f) => f.temp == true && f.name == file.name,
          this.fileList
        );
      }
      this.$emit("removed", file);
    },
    downloadFile(rs) {
      let a = document.createElement("a");
      a.href = rs.download;
      a.download = rs.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    download(id) {
      Helper.gqlMutate("Media", "getDownloadPath", {
        id: id,
        model: this.model,
      }).then((data) => {});
    },
    validateLimitSize(file) {
      if( this.limitSize <= 0) return true;
      if (this.limitSize > 0) {
        if (file.size / 1024 / 1024 > this.limitSize) {
          Swal.fire({
            title: this.$t("msg.limit_size_upload") + ' ' + this.limitSize + "MB",
            text: "",
            icon: "error",
            confirmButtonText: "OK",
          });
          // remove only file overed size
          this.$refs.upload.remove(file);
          return false;
        }
      }
      return true;
    },
    processFile(file, fileList) {
     if( this.validateLimitSize(file.raw) === false) return;
      if (this.accept) {
        let extension = file.name.split(".").pop();
        const types = this.accept.split(",");
        let list = types.reduce((a, b) => {
          let type = [];
          const selector = b.trim();
          switch (selector) {
            case "excel":
              type = [".xls", ".xlsx"];
              break;
            case "word":
              type = [".doc", ".docx"];
              break;
            case "image":
              type = [".png", ".gif", ".jpeg", ".jpg"];
              break;
            case "pdf":
              type = [".pdf"];
              break;
            default:
              break;
          }
          return a.concat(type);
        }, []);
        if (list.length > 0 && !list.includes("." + extension)) {
          Swal.fire({
            title: this.$t("validate.extension_upload", {
              extension: "[" + list.join(", ") + "]",
            }),
            text: "",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.reset();
          return;
        }
      }
      this.$emit("loading", true);
      this.uploadedFile = file.raw;

      let promise;

      if (this.graphModel === "Media") {
        promise = Helper.gqlUpload(this.graphModel, "uploadTemporaryMedia", {
          file: this.uploadedFile,
          model: this.model,
        });
      } else {
        promise = Helper.gqlUpload(this.graphModel, "uploadTemporaryMedia", {
          file: this.uploadedFile,
          token: this.token,
        });
      }

      this.loading = true;

      promise.then(
        async ({ data }) => {
          let res = JSON.parse(
            data.uploadTemporaryMedia ||
              data.jobboardUploadTemporaryMedia ||
              "{}"
          );

          let url = this.image_mime_types.includes(res.mime_type)
            ? res.url
            : "/img/icons/file.jpg";
          let created_at = moment(res.created_at).format("YYYY/MM/DD");
          this.fileList.push({
            id: res.id,
            name: "[" + created_at + "] " + res.name,
            path_name: res.name,
            url: url,
            temp: true,
            download: res.full_path,
          });

          this.tempFileList.push(res);

          if (this.autoUpload) await this.processUpload(this.id);

          this.loading = false;

          this.$emit("uploaded", res);
          this.$emit("loading", false);
        },
        (errors) => {
          const msg = errors.graphQLErrors.map((v) => v.message).join("");
          this.submitting = false;
          Swal.close();
          Swal.fire({
            title: msg,
            text: "",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.reset();
        }
      );
    },
    validate() {
      if (
        this.fileList.length == 0 ||
        this.fileList.length == this.removeFileList.length
      ) {
        Swal.fire({
          title: this.$t("model.notifications.no_data"),
          text: "",
          icon: "error",
          confirmButtonText: "OK",
        });

        return false;
      }

      return true;
    },
    processUpload(id) {
      this.loading = true;

      return new Promise(async (resolve, reject) => {
        if (this.fileList.length == 0) {
          resolve(false);
        } else {
          await this.processRemove(id);

          let promises = [];

          this.tempFileList.forEach((f) => {
            promises.push(
              new Promise((re, rj) => {
                if (this.graphModel === "Media") {
                  Helper.gqlUpload(this.graphModel, "attachMedia", {
                    id: id,
                    model: this.model,
                    path: f.path,
                    collection: this.collection,
                  }).then(
                    () => re(),
                    (err) => {
                      err.graphQLErrors.map(({ message, locations, path }) => {
                        if (message == "You have not permission upload") {
                          Swal.close();
                          Swal.fire({
                            title: this.$t("error.permission"),
                            text: "",
                            icon: "error",
                            confirmButtonText: "OK",
                          });
                        }
                      });
                    }
                  );
                } else {
                  Helper.gqlUpload(this.graphModel, "attachMedia", {
                    id: id,
                    model: this.model,
                    path: f.path,
                    collection: this.collection,
                    token: this.token,
                  }).then(() => re());
                }
              })
            );
          });

          Promise.all(promises).then(() => {
            this.tempFileList = [];
            this.removeFileList = [];

            if (!this.showListAfterUpload) {
              this.$refs.upload.clearFiles();
              this.fileList = [];
            }

            resolve(true);
          });
        }
      });
    },
    processRemove(id) {
      let promises = [];
      this.removeFileList.forEach((f) => {
        promises.push(
          new Promise((resolve, reject) => {
            if (this.graphModel === "media") {
              Helper.gqlUpload(this.graphModel, "detachMedia", {
                id: this.id,
                model: this.model,
                collection: this.collection,
                media_id: f.id,
              }).then(() => resolve());
            } else {
              Helper.gqlUpload(this.graphModel, "detachMedia", {
                id: this.id,
                model: this.model,
                collection: this.collection,
                media_id: f.id,
              }).then(() => resolve());
            }
          })
        );
      });

      return Promise.all(promises);
    },
    getFileList() {
      return this.fileList;
    },
    getFiles() {
      return this.tempFileList;
    },
    toggleUploadDragger() {
      let display = "block";
      if (!this.showUploadDragger) {
        display = "none";
      }
      const uploadDraggerEl =
        document.getElementsByClassName("el-upload-dragger");

      for (let i = 0; i < uploadDraggerEl.length; i++) {
        const element = uploadDraggerEl[i];
        element.style.display = display;
      }
    },
    removeTmpFile() {
      this.tempFileList = [];
      this.removeFileList = [];
      this.fileList = filter((f) => f.id, this.fileList);
    },
    processFileCustom(file, fileList) {
      this.uploadedFile = file.raw;
      return Helper.gqlUpload("Media", "uploadTemporaryMedia", {
        file: this.uploadedFile,
      }).then(({ data }) => {
        let res = JSON.parse(
          data.uploadTemporaryMedia || data.jobboardUploadTemporaryMedia || "{}"
        );
        let url = this.image_mime_types.includes(res.mime_type)
          ? res.url
          : "/img/icons/file.jpg";
        let created_at = moment(res.created_at).format("YYYY/MM/DD");
        this.fileList = [
          {
            id: res.id,
            name: "[" + created_at + "] " + res.name,
            path_name: res.name,
            url: url,
            temp: true,
            download: res.full_path,
          },
        ];
        this.tempFileList = [res];
      });
    },
    uploadFiles(id) {
      return new Promise((resolve, reject) => {
        let pathData = this.getUploadFiles();

        if (pathData.length > 0) {
          Helper.gqlMutate("Media", "attachMedias", {
            id: id,
            model: this.model,
            collection: this.collection,
            paths: pathData,
          }).then(({ data }) => {
            resolve(data.attachMedias);
          });
        } else {
          resolve(false);
        }
      });
    },
    getUploadFiles() {
      let pathData = [];
      let attachFiles = pluck("path", this.getFiles());

      if (attachFiles.length > 0) {
        attachFiles.forEach((path) => {
          let pathName = path.replace(/^.*[\\\/]/, "");

          if (this.mustHaveDescription) {
            if (this.descriptions[pathName]) {
              pathData.push({
                path: path,
                description: this.descriptions[pathName],
              });
            } else {
              throw "No Description";
            }
          } else {
            pathData.push({
              path: path,
              description: this.descriptions[pathName],
            });
          }
        });
      }

      return pathData;
    },
    updateDescription(f) {
      if (f.id) {
        Helper.gqlMutate("Media", "updateMediaDescription", {
          id: f.id,
          content: f.description,
        }).then(() => {
          console.log("complete");
        });
      } else {
        this.descriptions[f.path_name] = f.description;
      }
    },
    isShowPermissionDeleteFile() {
      if (!this.role_upload) return false;
      return !this.isNotShowIconRemove;
    },
  },
};
</script>

<style>
.el-upload {
  width: 100%;
}
.el-upload-list__item {
  transition: none !important;
}
.el-upload-dragger {
  width: 100%;
  height: auto;
  background: #f9f9f9;
}
.el-upload-dragger-desc {
  height: 200px;
}
.el-upload-dragger-desc .desc-1 {
  font-weight: bold;
}

.upload-tiny .el-upload-dragger {
  border: 0;
}

.upload-button-text {
  max-width: 150px;
  white-space: nowrap;
  width: 100%;
  text-emphasis: antiquewhite;
  -webkit-text-emphasis: antiquewhite;
  text-overflow: ellipsis;
  display: block;
}

.desc-box {
  border: 0;
  border-bottom: 1px solid #cfcfcf;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.media-remove {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: red;
}

.upload-demo.by-flex {
  gap: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.upload-demo.by-flex .el-upload.el-upload--picture {
  width: 50%;
}

.white-space-normal {
  white-space: normal;
}
</style>

export const state = () => ({
  filterEmployees: {
    selectedEmployeesSide: [],
    notSelectedEmployeesSide: [],
    confirmSelectedEmployees: [],
    originalEmployees: [],
  },
})

export const getters = {
  filterEmployees: (state) =>
    state.filterEmployees,
}

export const mutations = {
  SET_SELECTED_EMPLOYEES_SIDE(state, selectedEmployeesSide) {
    state.filterEmployees.selectedEmployeesSide =
      selectedEmployeesSide
  },
  SET_NOT_SELECTED_EMPLOYEES_SIDE(state, notSelectedEmployeesSide) {
    state.filterEmployees.notSelectedEmployeesSide =
      notSelectedEmployeesSide
  },
  SET_ORIGINAL_EMPLOYEES(state, originalEmployees) {
    state.filterEmployees.originalEmployees = originalEmployees
    state.filterEmployees.confirmSelectedEmployees =
      originalEmployees
  },
  CALCULATE_CONFIRM_SELECTED_EMPLOYEES(state, type) {
    const {
      selectedEmployeesSide,
      notSelectedEmployeesSide,
      confirmSelectedEmployees,
    } = state.filterEmployees
    switch (type) {
      case 'select':
        state.filterEmployees.confirmSelectedEmployees = [
          ...state.filterEmployees.confirmSelectedEmployees,
          ...notSelectedEmployeesSide,
        ].map((x) => ({ ...x, selected: false }))
      case 'deselect':
        const employeeIds = selectedEmployeesSide.map((x) => x.id)
        state.filterEmployees.confirmSelectedEmployees =
          state.filterEmployees.confirmSelectedEmployees
            .filter((x) => !employeeIds.includes(x.id))
            .map((x) => ({ ...x, selected: false }))
    }
  },
  CLEAR_ALL_TIMESHEET_SHIFTS_TEMPLATE_EMPLOYEES(state) {
    state.filterEmployees = {
      selectedEmployeesSide: [],
      notSelectedEmployeesSide: [],
      confirmSelectedEmployees: [],
      originalEmployees: [],
    }
  },
}

export const actions = {
  setSelectedEmployeesSide({ commit }, payload) {
    commit('SET_SELECTED_EMPLOYEES_SIDE', payload)
  },
  setNotSelectedEmployeesSide({ commit }, payload) {
    commit('SET_NOT_SELECTED_EMPLOYEES_SIDE', payload)
  },
  setOriginalEmployees({ commit }, payload) {
    commit('SET_ORIGINAL_EMPLOYEES', payload)
  },
  calculateConfirmSelectedEmployees({ commit }, employees, type) {
    commit('CALCULATE_CONFIRM_SELECTED_EMPLOYEES', employees, type)
  },
  clearTimesheetShiftsTemplateEmployees({ commit }) {
    commit('CLEAR_ALL_TIMESHEET_SHIFTS_TEMPLATE_EMPLOYEES')
  },
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{class:{ open: _vm.visible },attrs:{"no-body":""},on:{"mouseenter":_vm.collapseOpen,"mouseleave":_vm.collapseClose}},[(_vm.showTitle)?_c('b-card-header',{class:{ collapsed: !_vm.visible },attrs:{"aria-expanded":_vm.visible ? 'true' : 'false',"aria-controls":_vm.collapseItemID,"role":"tab","data-toggle":"collapse"},on:{"click":function($event){return _vm.updateVisible(!_vm.visible)}}},[_vm._t("header",function(){return [_c('span',{staticClass:"collapse-title"},[_vm._v(_vm._s(_vm.title))])]})],2):_vm._e(),_vm._v(" "),_c('b-collapse',{attrs:{"id":_vm.collapseItemID,"accordion":_vm.accordion,"role":"tabpanel"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-card-body',{class:{
        'p-0': _vm.noPadding,
        'pb-0': _vm.noPaddingBottom,
        'pt-0': _vm.noPaddingTop,
        'pl-0': _vm.noPaddingLeft,
        'pr-0': _vm.noPaddingRight,
      }},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
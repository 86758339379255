<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    :tag="templateTag"
  >
    <div class="form-group" :style="noMarginBottom ? 'margin-bottom: 0;' : ''">
      <div class="has-label">
        <el-switch
          v-model="valueData"
          :disabled="disabled"
          v-bind="$attrs"
        />
        <span class="ml-2">{{ label }}</span>
      </div>
      <div v-if="errors[0]" class="mt-2">
        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VeeSwitch',
  props: {
    value: {
      type: [Boolean, String, Number],
      default: () => false,
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    templateTag: {
      type: String,
      default: () => 'div',
    },
    rules: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueData: this.value,
    }
  },
  watch: {
    value() {
      this.valueData = this.value
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<template>
  <div class="wrapper">
    <notifications />
    <dashboard-navbar-left />
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" />
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <nuxt />
        </fade-transition>
      </div>
      <portal-target
        name="content-renderer-sidebar-left"
        slim
      />
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import { FadeTransition } from 'vue2-transitions'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import DashboardNavbar from './components/DashboardNavbar.vue'
import ContentFooter from './components/ContentFooter.vue'
import DashboardContent from './components/Content.vue'
import DashboardNavbarLeft from "./components/DashboardNavbarLeft.vue";

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar (className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  middleware: 'auth',
  components: {
    DashboardNavbarLeft,
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  },
  computed: {
    currentLocale() {
      return this.$store.state.lang.locale;
    }
  },
  watch: {
    currentLocale: {
      immediate: true,
      handler(newLocale) {
        this.$i18n.locale = newLocale;
      }
    }
  },
  mounted () {
    document.body.classList.add(`lang-${this.$i18n.locale}`)
    this.initScrollbar()
  },
  methods: {
    initScrollbar () {
      const isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('sidenav')
      }
    },
  }
}
</script>
<style lang="scss">
</style>
